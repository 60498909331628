var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      staticClass: "ma-0 pa-0",
      attrs: { fluid: "" },
    },
    [
      _c("v-divider"),
      _c(
        "v-row",
        { staticStyle: { width: "100%" } },
        [
          _c(
            "v-col",
            {
              staticClass: "ml-3 pl-2 mr-0 pr-0 my-3 py-2",
              attrs: { cols: "6" },
            },
            [
              _c("SearchFilters", {
                attrs: { disabled: _vm.loading },
                on: { search: _vm.getPromoList },
                model: {
                  value: _vm.searchParams,
                  callback: function ($$v) {
                    _vm.searchParams = $$v
                  },
                  expression: "searchParams",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-0 my-3 py-2" },
            [
              _c(
                "v-btn",
                {
                  staticStyle: {
                    "border-top-left-radius": "0px",
                    "border-bottom-left-radius": "0px",
                    border: "1px solid #9e9e9e",
                    "border-left": "0px",
                  },
                  attrs: {
                    loading: _vm.loading,
                    disabled: _vm.loading,
                    depressed: "",
                    height: "40",
                    color: "accent",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.getPromoList.apply(null, arguments)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-magnify")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "my-auto", attrs: { align: "end" } },
            [
              _vm.promoDetail
                ? _c(
                    "v-btn",
                    {
                      staticClass: "primary--text",
                      attrs: { text: "", depressed: "" },
                      on: {
                        click: function ($event) {
                          _vm.promoDetail = false
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-minus"),
                      ]),
                      _vm._v("Back to promotions "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.promoDetail
        ? _c("v-data-table", {
            staticClass: "rounded-0",
            attrs: {
              headers: _vm.headers,
              height: _vm.tableHeight,
              items: _vm.items,
              "loading-text": "Searching Available Data...Please Wait",
              loading: _vm.loading,
              "fixed-header": "",
              "hide-default-footer": "",
              "items-per-page": _vm.pageSize,
              "item-class": _vm.itemRowBackground,
              "item-key": "id",
              "loader-height": "5",
              "no-data-text": "No Data to Display",
              dense: "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.promo_number`,
                  fn: function ({ item }) {
                    return [
                      item.promo_number
                        ? _c("span", [_vm._v(_vm._s(item.promo_number))])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.name`,
                  fn: function ({ item }) {
                    return [
                      item.name
                        ? _c("span", [_vm._v(_vm._s(item.name))])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.start_dt`,
                  fn: function ({ item }) {
                    return [
                      item.start_dt
                        ? _c("span", [_vm._v(_vm._s(item.start_dt))])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.end_dt`,
                  fn: function ({ item }) {
                    return [
                      item.end_dt
                        ? _c("span", [_vm._v(_vm._s(item.end_dt))])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.amount_off`,
                  fn: function ({ item }) {
                    return [
                      item.amount_off
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(item.amount_off)
                                )
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.promotion_price`,
                  fn: function ({ item }) {
                    return [
                      item.promotion_price
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(
                                    item.promotion_price
                                  )
                                )
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.rebate_amount`,
                  fn: function ({ item }) {
                    return [
                      item.rebate_amount
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(item.rebate_amount)
                                )
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.variance`,
                  fn: function ({ item }) {
                    return [
                      item.variance
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(_vm.formatCurrencyDisplay(item.variance))
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.billback_allowance`,
                  fn: function ({ item }) {
                    return [
                      item.accrual_amt
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(item.accrual_amt)
                                )
                            ),
                          ])
                        : item.billback_allowance
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(
                                    item.billback_allowance
                                  )
                                )
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.amap_allowance`,
                  fn: function ({ item }) {
                    return [
                      item.amap_allowance
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(item.amap_allowance)
                                )
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.early_buy_allowance`,
                  fn: function ({ item }) {
                    return [
                      item.early_buy_allowance
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(
                                    item.early_buy_allowance
                                  )
                                )
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.related_promos`,
                  fn: function ({ item }) {
                    return [
                      item.related_promos
                        ? _c(
                            "v-tooltip",
                            {
                              staticClass: "mx-auto",
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                icon: "",
                                                small: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.viewRelatedPromos(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "mx-auto" },
                                              [_vm._v("mdi-view-list")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v("View Related Promos")])]
                          )
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: `item.actions`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              ;(_vm.promoDetail = true),
                                (_vm.selectedPromo = item),
                                _vm.getPromoItemsByPromo()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-information")])],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        : _c("v-data-table", {
            staticClass: "rounded-0",
            attrs: {
              headers: _vm.pigheaders,
              height: _vm.tableHeight,
              items: _vm.promoItemGroups,
              "loading-text": "Searching Available Data...Please Wait",
              loading: _vm.loading,
              "fixed-header": "",
              "hide-default-footer": "",
              "items-per-page": _vm.pageSize,
              "item-key": "id",
              "loader-height": "5",
              "no-data-text": "No Data to Display",
              "group-by": "promo_item_group.id",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `group.header`,
                  fn: function ({ group, items, headers }) {
                    return [
                      _c(
                        "td",
                        { attrs: { colspan: headers.length } },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c("v-col", { staticClass: "truncate" }, [
                                _c("span", [
                                  _c("b", [_vm._v("Promo Item Group")]),
                                  _c("br"),
                                ]),
                                items[0].promo_item_group.name
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            items[0].promo_item_group.name
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", [_vm._v("N/A")]),
                              ]),
                              _c("v-col", { staticClass: "truncate" }, [
                                _c("span", [
                                  _c("b", [_vm._v("Promo Name")]),
                                  _c("br"),
                                  _vm._v(
                                    " " + _vm._s(_vm.selectedPromo.name) + " "
                                  ),
                                ]),
                              ]),
                              _c("v-col", [
                                _c("span", [
                                  _c("b", [_vm._v("Promo Category")]),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.selectedPromo.promo_category_name
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("v-col", [
                                _c("span", [
                                  _c("b", [_vm._v("Promo Start Date")]),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatDateDisplay(
                                          _vm.selectedPromo.start_dt
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("v-col", [
                                _c("span", [
                                  _c("b", [_vm._v("Promo End Date")]),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatDateDisplay(
                                          _vm.selectedPromo.end_dt
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c("v-col", [
                                _c("span", [
                                  _c("b", [_vm._v("Billing Mode")]),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.selectedPromo.billing_mode_name
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { align: "right" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "mr-12 white--text",
                                                      staticStyle: {
                                                        height: "25px",
                                                        width: "25px",
                                                      },
                                                      attrs: {
                                                        readonly:
                                                          _vm.feature.promo_item_group_ids.includes(
                                                            group
                                                          ),
                                                        small: "",
                                                        fab: "",
                                                        color:
                                                          _vm.feature.promo_item_group_ids.includes(
                                                            group
                                                          )
                                                            ? "green"
                                                            : "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.feature.promo_item_group_ids.includes(
                                                            group
                                                          )
                                                            ? ""
                                                            : _vm.linkPromo(
                                                                group
                                                              )
                                                        },
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.feature.promo_item_group_ids.includes(
                                                              group
                                                            )
                                                              ? "mdi-check"
                                                              : "mdi-plus"
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.feature.promo_item_group_ids.includes(
                                              group
                                            )
                                              ? "Added"
                                              : "Add"
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: `item.promotion_price`,
                  fn: function ({ item }) {
                    return [
                      item.promo_item_group.promotion_price
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(
                                    item.promo_item_group.promotion_price
                                  )
                                )
                            ),
                          ])
                        : _vm.selectedPromo.promotion_price
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(
                                    _vm.selectedPromo.promotion_price
                                  )
                                )
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.rebate_amount`,
                  fn: function ({ item }) {
                    return [
                      item.promo_item_group.rebate_amount
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(
                                    item.promo_item_group.rebate_amount
                                  )
                                )
                            ),
                          ])
                        : _vm.selectedPromo.rebate_amount
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(
                                    _vm.selectedPromo.rebate_amount
                                  )
                                )
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.billback_allowance`,
                  fn: function ({ item }) {
                    return [
                      item.accrual_amt
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(item.accrual_amt)
                                )
                            ),
                          ])
                        : item.promo_item_group.billback_allowance
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(
                                    item.promo_item_group.billback_allowance
                                  )
                                )
                            ),
                          ])
                        : _vm.selectedPromo.billback_allowance
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(
                                    _vm.selectedPromo.billback_allowance
                                  )
                                )
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.amap_allowance`,
                  fn: function ({ item }) {
                    return [
                      item.promo_item_group.amap_allowance
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(
                                    item.promo_item_group.amap_allowance
                                  )
                                )
                            ),
                          ])
                        : _vm.selectedPromo.amap_allowance
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(
                                    _vm.selectedPromo.amap_allowance
                                  )
                                )
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.early_buy_allowance`,
                  fn: function ({ item }) {
                    return [
                      item.promo_item_group.early_buy_allowance
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(
                                    item.promo_item_group.early_buy_allowance
                                  )
                                )
                            ),
                          ])
                        : _vm.selectedPromo.early_buy_allowance
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(
                                    _vm.selectedPromo.early_buy_allowance
                                  )
                                )
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.item.sale_price`,
                  fn: function ({ item }) {
                    return [
                      item.item.sale_price
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(
                                    item.item.sale_price
                                  )
                                )
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.item.cost`,
                  fn: function ({ item }) {
                    return [
                      item.item.cost
                        ? _c("span", [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(item.item.cost)
                                )
                            ),
                          ])
                        : _c("span", [_vm._v("-")]),
                    ]
                  },
                },
                {
                  key: `item.item.unit_cost`,
                  fn: function ({ item }) {
                    return [
                      item.item.qty_per_case
                        ? _c("span", [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  _vm.formatCurrencyDisplay(
                                    _vm.calcUnitCost(item)
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
      _c(
        "v-toolbar",
        { attrs: { dense: "", flat: "", color: "#fafafa" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-col", { attrs: { cols: "9" } }),
              _c(
                "v-col",
                {
                  staticStyle: { "max-width": "150px" },
                  attrs: { cols: "2", justify: "end" },
                },
                [
                  _c("v-select", {
                    staticClass: "mt-8",
                    attrs: {
                      dense: "",
                      items: _vm.pageSizes,
                      label: "Items Per Page",
                      select: "",
                      "menu-props": {
                        top: true,
                        offsetY: true,
                        maxHeight: 500,
                      },
                    },
                    model: {
                      value: _vm.pageSize,
                      callback: function ($$v) {
                        _vm.pageSize = $$v
                      },
                      expression: "pageSize",
                    },
                  }),
                ],
                1
              ),
              !_vm.promoDetail
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mx-2",
                      attrs: {
                        icon: "",
                        large: "",
                        disabled: _vm.disablePrevious,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.previous()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                    1
                  )
                : _vm._e(),
              !_vm.promoDetail
                ? _c("small", [_vm._v("Page " + _vm._s(_vm.page))])
                : _vm._e(),
              !_vm.promoDetail
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mx-2",
                      attrs: { icon: "", large: "", disabled: _vm.disableNext },
                      on: {
                        click: function ($event) {
                          return _vm.next()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                    1
                  )
                : _vm._e(),
              _vm.promoDetail
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mx-2",
                      attrs: {
                        icon: "",
                        large: "",
                        disabled: _vm.disablePreviousPIG,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.previousPIG()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                    1
                  )
                : _vm._e(),
              _vm.promoDetail
                ? _c("small", [_vm._v("Page " + _vm._s(_vm.pigpage))])
                : _vm._e(),
              _vm.promoDetail
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mx-2",
                      attrs: {
                        icon: "",
                        large: "",
                        disabled: _vm.disableNextPIG,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.nextPIG()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.relatedPromosDialog
        ? _c("RelatedPromos", {
            attrs: {
              promo: _vm.currentPromo,
              related_promos: JSON.parse(_vm.currentPromo.related_promos),
              groupIds: _vm.feature.promo_item_group_ids,
            },
            on: {
              addPromoItemGroups: _vm.addPromoItemGroups,
              close: _vm.closeRelatedPromos,
            },
            model: {
              value: _vm.relatedPromosDialog,
              callback: function ($$v) {
                _vm.relatedPromosDialog = $$v
              },
              expression: "relatedPromosDialog",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }