var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c("v-autocomplete", {
        attrs: {
          value: _vm.selectedVendors,
          items: _vm.vendors,
          "item-text": "name",
          "item-value": "vendor_id",
          label: "Vendors",
          loading: _vm.searching,
          "search-input": _vm.vendorSearch,
          "menu-props": { closeOnContentClick: true },
          "background-color": "input",
          placeholder: _vm.isAdmin ? "Start typing to search" : null,
          "hide-no-data": "",
          "hide-details": "",
          multiple: "",
          "cache-items": "",
          "return-object": "",
          "small-chips": "",
          "deletable-chips": "",
          dense: "",
          outlined: "",
        },
        on: {
          "update:searchInput": function ($event) {
            _vm.vendorSearch = $event
          },
          "update:search-input": function ($event) {
            _vm.vendorSearch = $event
          },
          change: function ($event) {
            return _vm.$emit("update:selectedVendors", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item, attrs, on }) {
              return [
                _c(
                  "v-list-item",
                  _vm._g(
                    _vm._b(
                      { attrs: { color: "primary" } },
                      "v-list-item",
                      attrs,
                      false
                    ),
                    on
                  ),
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [
                          _vm._v(" " + _vm._s(item.name) + " "),
                        ]),
                        _c("v-list-item-subtitle", [
                          _vm._v(" " + _vm._s(item.party_type_name) + " "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }